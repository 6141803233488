import { NavLink } from 'react-router-dom'
import WalletConnect from './WalletConnect'
import dischordicon from '../../assets/icons/discord_black_logo_icon.png'
import twittericon from '../../assets/icons/social_Twitter_icon.png'
import instagramicon from '../../assets/icons/instagram_black_logo_icon.png'
// import openseaicon from '../../assets/icons/Logomark-Transparent White.png'
import openseaicon from '../../assets/icons/OpenSea-Full-Logo (light).png'
import { useMemo } from 'react'
import { useAccount, useContractRead } from 'wagmi'
import CONTRACT_ABI from '../../common/abis/LookingIn.json'
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS

export const MenuNavLinks = ({
    onNav = () => () => { },
    ...props
}) => {
    const { address, isConnected } = useAccount()
    console.log('address', address)
    const {
        data: balanceOf,
        refetch
    } = useContractRead({
        address: CONTRACT_ADDRESS,
        abi: CONTRACT_ABI,
        args: [address],
        functionName: 'balanceOf',
    })

    const isHolding = useMemo(() => isConnected 
        ? Number(balanceOf) > 0 ? true : false
        : false
    , [balanceOf, isConnected])
    console.log(isHolding)
    return (
        <>
            <NavLink {...props} to="/" onClick={onNav("/")}>HOME</NavLink>
            <NavLink {...props} to="about" onClick={onNav("/about")}>ABOUT</NavLink>
            {isHolding ? <NavLink {...props} to="/test" onClick={onNav("/test")}>TAKE THE TEST</NavLink> : null}
            {isHolding ? <NavLink {...props} to="my-tests" onClick={onNav("/my-tests")}>ALL YOUR TESTS</NavLink> : null}
            <NavLink {...props} to="leaderboard" onClick={onNav("/leaderboard")}>MINT LEADERBOARD</NavLink>
            {/* <NavLink {...props} to="contact" onClick={onNav("/contact")}>CONTACT</NavLink> */}
            {/* <NavLink {...props} to="contact" onClick={onNav("/credits")}>GUESS WHO YOU ARE</NavLink> */}
        </>
    )
}

const Menu = ({
    onNav,
    ...props
}) => (
    <div className={`flex flex-col gap-6 items-center text-white py-[17vmin] pb-[17vh] sm:pb-[17vmin]`}>
        <div className={`flex justify-center gap-4`}>
            <WalletConnect
                className={`
                    bg-alphawhite
                    inline-block px-6 py-3 rounded-full
                `}
            />
        </div>
        <MenuNavLinks onNav={onNav} />
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            color: 'white'
        }}>
            <a target="_blank" rel="noopener" className="social-icon hover:scale-105 hover:text-[tomato]" id="about-social-dicord" href='https://discord.gg/snuffynyc'><img src={dischordicon} alt="dischord icon" /></a>
            <a target="_blank" rel="noopener" className="social-icon hover:scale-105 hover:text-[tomato]" id="about-scial-twitter" href='https://twitter.com/SnuffyNyc'><img src={twittericon} alt="twitter icon" /></a>
            <a target="_blank" rel="noopener" className="social-icon hover:scale-105 hover:text-[tomato]" id="about-social-instagram" href='https://www.instagram.com/snuffy.nyc/?hl=en'><img src={instagramicon} alt="instagram icon" /></a>
        </div>
        <div className='flex justify-center'>
            <a target="_blank" rel="noopener"
                className='hover:scale-105'
                id="about-social-instagram"
                href={process.env.REACT_APP_OPENSEA_LOOKING_IN_URL}
            >
                <img src={openseaicon} alt="opensea icon"
                    className={`
                    h-10
                `}
                />
            </a>
        </div>

        <div className='my-4 flex flex-wrap gap-4 justify-center'>
            <NavLink {...props} to="/credits" onClick={onNav("/credits")}>CREDITS</NavLink> //
            <NavLink {...props} to="/privacy-policy" onClick={onNav("/privacy-policy")}>PRIVACY POLICY</NavLink>
        </div>
    </div>
)

export default Menu