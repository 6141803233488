import React from 'react';
import './css/index.css';
import App from './modules/App';
import reportWebVitals from './reportWebVitals';
// import './fonts/DrukWideBold.ttf'
// import './fonts/Demode.ttf'
// import './fonts/SnuffyFont2.otf'
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');

console.log('v0.1.0')

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />)
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
