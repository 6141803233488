import React, { lazy, Suspense, useEffect, useState } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    useParams,
    useLocation,
    Navigate,
} from 'react-router-dom';
import { Web3Modal } from '@web3modal/react';
import {
    EthereumClient,
    modalConnectors,
    walletConnectProvider,
} from '@web3modal/ethereum';
import { mainnet, configureChains, createClient, WagmiConfig, useAccount, useSignMessage, useSigner, goerli } from 'wagmi';
import ErrorsNotification from './errors/ErrorsNotifications';
import CornerOverlay from './misc/CornerOverlay';
import { pushGlobalError } from './errors/useErrors';
import { contractMessages$$ } from './snuffy/observables';
import { authenticateIdentity, fetchMe, VENDORS } from './user-profile/api';
import { identity$$ } from './user-profile/observables';
import WalletConnect from './misc/WalletConnect';
import MintButton, { MintDialog, mintDialog$$ } from '../common/components/MintButton';
// import Loading from '../common/components/Loading';
import CONTRACT_ABI from '../common/abis/LookingIn.json'
import ConfirmEmailSignup from './email-signup/ConfirmEmailSignup';
import { useObservableState } from 'simple-observable-state';
// import LookingInWelcomePrompt from './looking-in/LookingInWelcomePrompt';
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS

console.log('contract address:', CONTRACT_ADDRESS)

const PrivacyPolicy = lazy(() => import('../common/components/PrivacyPolicy'))
const Credits = lazy(() => import('./misc/Credits'))
const NotFound = lazy(() => import('./misc/NotFound'))

// const LookingInSplash = lazy(() => import('./looking-in/LookingInSplash'));
const MintPage = lazy(() => import('../common/components/MintPage'));
const LookingInAbout = lazy(() => import('./looking-in/LookingInAbout'));
const LookingInContactForm = lazy(() => import('./looking-in/LookingInContactForm'))
const LookingIn = lazy(() => import('./looking-in/LookingIn'))
const LookingInAllTests = lazy(() => import('./looking-in/LookingInAllTests'))
const RevealTest = lazy(() => import('./looking-in/RevealTest'))
const TestResults = lazy(() => import('./looking-in/TestResults'))
const LookingInLeaderboard = lazy(() => import('./looking-in/LookingInLeaderboard'))
const ResetEmailPasswordForm = lazy(() => import('./email-signup/ResetEmailPasswordForm'))


const Main = () => {
    const { address, isDisconnected, isConnected } = useAccount()
    // const { data: signer } = useSigner()
    // const {
    //     status,
    //     isLoading,
    //     signMessageAsync
    // } = useSignMessage({
    //     message: 'I am who I say I am.'
    // })
    // run on address change
    useEffect(() => {
        if (isConnected && address) {
            console.log('address changed', address)
            authenticateIdentity({
                vendor: VENDORS.eth_addr,
                key: address
            })
                .then(sessionID => fetchMe())
                .then(identity => identity$$.next(identity))
                .catch(console.error)
            // signMessageAsync()
            //     .then( signedMessage => {
            //         console.log('signedMessage', signedMessage)
            //         return authenticateIdentity({
            //             vendor: VENDORS.eth_addr,
            //             key: address,
            //             proof: signedMessage
            //         })
            //     })
        }
    }, [address, isConnected])

    const [mintDialog] = useObservableState(mintDialog$$)
    return <>
        <main className={`relative w-screen h-screen text-gray-600 overflow-x-scroll`}>
            <div className={`z-50 fixed top-0 left-0 flex justify-center w-full pointer-events-none`}>
                {(!address || isDisconnected || !isConnected)
                    ? <WalletConnect
                        className={`
                            bg-alphablack text-white pointer-events-auto
                            text-md
                            inline-block px-6 py-3 rounded-full mt-2
                        `}
                    // ctaText={'MINT TOKENS'}
                    // connectedCallback={() => {console.log('connected')}}
                    />
                    : !mintDialog.isOpen
                        ? <MintButton
                            animate={false}
                            className={`
                            pointer-events-auto
                            bg-lightGreen
                            text-md
                            inline-block px-8 py-3 rounded-full mt-2
                        `}
                        />
                        : null}
            </div>
            <ErrorsNotification />
            <CornerOverlay />
            <Outlet />
            <MintDialog
                contractAddress={CONTRACT_ADDRESS}
                contractAbi={CONTRACT_ABI}
                className={`z-60`}
            />
            {/* <CookieBanner /> */}
        </main>
    </>
}

const AppLayout = () => {

    // WalletConnect projectId
    const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || ''

    // chains
    const chains = [mainnet]

    // wagmi client
    const { provider } = configureChains(chains, [
        walletConnectProvider({ projectId }),
    ])
    const wagmiClient = createClient({
        autoConnect: true,
        connectors: modalConnectors({ appName: 'looking-in', chains }),
        provider,
    })

    // Web3Modal Ethereum Client
    const ethereumClient = new EthereumClient(wagmiClient, chains)

    useEffect(() => {
        const sub = contractMessages$$.subscribe(({ error, success }) => {
            pushGlobalError(error)
        })

        return () => { sub.unsubscribe() }
    }, [])

    return (<>
        <WagmiConfig client={wagmiClient}>
            <Main />
        </WagmiConfig>
        <Web3Modal
            accentColor="default"
            ethereumClient={ethereumClient}
            projectId={projectId}
            theme="dark"
        />
    </>
    )
}

const App = () => (
    <BrowserRouter>
        <Suspense fallback={
            <div className={`w-screen h-screen bg-transparent flex`}>
                <h1 className={`m-auto`}>Loading...</h1>
            </div>
        }>
            <Routes>
                <Route path="/" element={<AppLayout />}>
                    {/* <Route index element={<LookingInWelcomePrompt />} /> */}
                    <Route index element={<MintPage 
                        contractAddress={CONTRACT_ADDRESS}
                        contractAbi={CONTRACT_ABI}
                    />} />
                    <Route path="test" element={<LookingIn />} />
                    <Route path="about" element={<LookingInAbout />} />
                    <Route path="contact" element={<LookingInContactForm />} />
                    <Route path="my-tests" element={<LookingInAllTests />} />
                    <Route path="/confirm/email-signup/:id" element={<ConfirmEmailSignup />} />
                    <Route path="/reset/password/:id" element={<ResetEmailPasswordForm />} />
                    <Route path="leaderboard" element={<LookingInLeaderboard />} />
                    <Route path="test/reveal/:id" element={<RevealTest />} />
                    <Route path="test/result/:id" element={<TestResults />} />
                    {/* <Route path="loading" element={<Loading />} /> */}
                    {/* STATICS */}

                    {/* <Route path="statics/*"  element={<StaticVideoPage />} /> */}
                    {/* MISC */}
                    {/* <Route path="termsandconditions" element={<Terms />} /> */}
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="credits" element={<Credits />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Suspense>
    </BrowserRouter>
)

export default App

// function useFitParentWidthFontSize() {
//     const once = useRef()
//     const [node, setnode] = useState(void 0)
//     const ref = useCallback(node => {
//         if (!node) { return }
//         if (once.current) { return }
//         once.current = true
//         setnode(node)
//     }, [])

//     useEffect(() => {
//         if (!node) { return }
//         const widthObserver = new ResizeObserver(entries => {
//             const entry = entries[0]
//             const cr = entry.contentRect;
//             console.log('Element:', entry.target);
//             console.log(`Element size: ${cr.width}px x ${cr.height}px`);
//             console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);

//             // take initial measurements
//             const parentWidth = node.parentElement?.clientWidth
//             if (parentWidth === null) { return }

//             function getFontSizeNumber(node) {
//                 return Number(node.style.fontSize.replace('px', ''))
//             }
//             function incrementNodeFontSize(node, x = 1) {
//                 const fontsize = getFontSizeNumber(node)
//                 node.style.fontSize = (fontsize + x) + 'px'
//             }

//             node.style.display = 'block'
//             node.style.maxWidth = '100%'
//             let startSize = 5
//             node.style.fontSize = startSize + 'px'
//             while (node.offsetWidth === parentWidth) {
//                 let oHeight = node.offsetHeight
//                 let oFontsize = getFontSizeNumber(node)
//                 incrementNodeFontSize(node, 1)
//                 let heightDelta = (node.offsetHeight - oHeight) / oHeight
//                 let fontsizeDelta = (getFontSizeNumber(node) - oFontsize) / oFontsize
//                 const hasLineBreak = heightDelta > (2 * fontsizeDelta)
//                 if (hasLineBreak) {
//                     incrementNodeFontSize(node, -1)
//                     break;
//                 }
//                 if (getFontSizeNumber(node) === 83) {
//                     break;
//                 }
//             }
//         })

//         widthObserver.observe(node)

//         return () => { widthObserver.unobserve(node) }
//     }, [node])

//     return ref
// }