import { useParams } from "react-router-dom"
import MovingBackground from "../../common/components/MovingBackground"
import { useEmailConfirmationCode } from "../user-profile/hooks"

export default function ConfirmEmailSignup(){
    const { id } = useParams()
    const { isError } = useEmailConfirmationCode(id)
    return <div className="py-[17vmin] pb-[17vh] sm:pb-[17vmin] min-h-full w-full flex flex-col justify-center items-center">
        <MovingBackground />
        <div className="p-6 bg-alphablack text-white rounded-md flex flex-col gap-8 mx-2">
            <p>Confirming Sign Up....<br/></p>
            {isError && <p>Oops!! Something went wrong, please try again.</p>}
        </div>
    </div>
}