import { useSpring, animated } from "@react-spring/web"
import purpleBg from '../../assets/png/purple-bg.png'
import mosaiccroppedurl from '../../assets/png/facets-mosaic-transparent.medium.png'

export function MovingBackground({
    noPaint
}) {
    const moveLoopSpring = useSpring({
        from: { x: 0, y: 0, scale: 1 },
        to: [
            { x: 0, y: 5, scale: 1.1 },
            { x: 10, y: 5, scale: 1.2 },
            { x: -5, y: 10, scale: 1.1 },
            { x: 0, y: 0, scale: 1 },
        ],
        loop: true,
        delay: 3000,
        config: {
            duration: 10000
        }
    })

    return <>
        {/* background */}
        {/* {noPaint */}
        {true
            ? null
            : <div
                className='fixed top-0 left-0 w-screen h-screen bg-no-repeat bg-cover -z-20'
                style={{ backgroundImage: `url(${purpleBg})` }}
            ></div>}
        {/* <animated.div
            className='fixed top-0 left-0 w-screen h-screen bg-no-repeat bg-cover -z-10 opacity-60 blur-sm'
            style={{
                backgroundImage: `url(${mosaiccroppedurl})`,
                ...moveLoopSpring
            }}
        ></animated.div> */}
        <animated.div
            className='fixed top-0 left-0 w-screen h-screen bg-no-repeat bg-cover -z-10 opacity-60'
            style={{
                backgroundImage: `url(${mosaiccroppedurl})`,
                ...moveLoopSpring
            }}
        ></animated.div>
    </>
}

export default MovingBackground