import { animated, useSpring } from '@react-spring/web'
import { pulseLoop } from '../springs';
import { useCallback } from 'react';
import Dialog from '../../modules/misc/Dialog'
import { ObservableState, useObservableState } from 'simple-observable-state';
import MintPage from './MintPage';

export const mintDialog$$ = new ObservableState({
    isOpen: false,
    isLoading: false,
})

export function MintButton({
    // test,
    // contractAddress,
    // contractAbi,
    animate = true,
    className,
    ...props
}) {
    const pulseSpring = useSpring(pulseLoop('white', 'tomato'))
    const [{ isLoading }] = useObservableState(mintDialog$$)
    return <animated.button
        style={{
            ...((isLoading || !animate) ? {} : pulseSpring)
        }}
        disabled={isLoading}
        className={className}
        onClick={e => {
            e.preventDefault()
            mintDialog$$.next(dialog => { dialog.isOpen = !dialog.isOpen })
        }}
        {...props}
    >
        <span
            style={{ transform: 'scale(1.5)' }}
            className={
                isLoading
                    ? 'text-white'
                    : ''
            }>
            MINT{isLoading && (<span className='text-[tomato]'>ING.. </span>)}
        </span>
    </animated.button>
}

export default MintButton

export function MintDialog({
    test,
    contractAddress,
    contractAbi,
    className,
    ...props
}) {
    
    const [{ isOpen }, $$] = useObservableState(mintDialog$$)
    const closeDialog = useCallback(e => {
        e.stopPropagation()
        // setIsOpen(false)
        $$.next(dialog => { dialog.isOpen = false })
    }, [$$])

    return <Dialog
        isOpen={!!isOpen}
        onDismiss={closeDialog}
        className={`
                    py-[17vmin] pb-[17vh] sm:pb-[17vmin]
                    bg-alphablackDark
                    [backdrop-filter:blur(5px)]
                    overflow-auto
                    ${className}
                `}
    >
        <MintPage
            {...{
                test,
                contractAddress,
                contractAbi,
                onNavigate: closeDialog,
                showBackground: false,
                ...props
            }} 
        />
        {/* <MintModal
            {...{
                test,
                contractAddress,
                contractAbi,
                onNavigate: closeDialog,
                showBackground: false,
                ...props
            }} 
        /> */}
    </Dialog>;
}