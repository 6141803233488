import { delay, distinctUntilKeyChanged, filter, map, merge, mergeMap, of, share, Subject, tap } from 'rxjs'


const _error$$ = new Subject()
export const getErrorEvents$$ = (ttl=1000) => _error$$.pipe(
    // distinctUntilKeyChanged( 'message' ),
    filter(x => !!x),
    map(error => {
        let timestamp = Date.now()
        return [{error, action: 'enter', timestamp}, timestamp]
    }),
    mergeMap( ([event, timestamp]) => merge(
        of(event),
        of({error: event.error, action: 'exit', timestamp}).pipe(delay(ttl))
    ) ),
    share()
)

export function nextError(error){
    _error$$.next(error)
}
// const MAX_STACK_CNT = 4
// shiftArray( errors, MAX_STACK_CNT )