import { identity } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pushGlobalError } from '../errors/useErrors'
import { postEmailConfirmation } from './api'
import { identity$$ } from './observables'

export function useEmailConfirmationCode(code){
    const [busy, setBusy] = useState(false)
    const [isError, setIsError] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if(code){
            setBusy(true)
            postEmailConfirmation(code)
                .then( identity => {
                    pushGlobalError(<div className={`bg-green-300 text-green-900 py-2 px-6`}>
                        SUCCESS SIGNUP!
                    </div>)
                    identity$$.next(identity)
                    navigate('/')
                })
                .catch( error => {
                    pushGlobalError(error)
                    setIsError(true)
                })
                .finally(() => {
                    setBusy(false)
                })
        }
    }, [code])

    return {
        busy,
        isError
    }
}