import React, { useEffect } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useContractRead, useProvider } from 'wagmi';
import { useCallback } from 'react';
import { pushGlobalError } from '../errors/useErrors';
import copy from 'copy-to-clipboard'
import CONTRACT_ABI from '../../common/abis/LookingIn.json'
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS

const WalletConnect = ({
    className,
    ctaText='CONNECT',
    // connectedCallback,
    ...props
}) => {
    const { open } = useWeb3Modal()
    const { address, isConnected } = useAccount()
    const provider = useProvider()
    
    // balanceOf
    const {
        data: balanceOf,
        // isError: isErrorMintPrice,
        // error,
        // isLoading: isLoadingMintPrice
    } = useContractRead({
        address: CONTRACT_ADDRESS,
        abi: CONTRACT_ABI,
        args: [address],
        functionName: 'balanceOf',
    })

    const showActiveWalletAccount = useCallback( e => {
        copy(address);
        window.confirm(`Copied to clipboard: ${address}`)
    }, [address])

    const onConnect = useCallback(e => {
        if (!provider) {
            pushGlobalError(new Error('No Eth provider is installed'))
        } else if (!isConnected || !address) {
            open()
        } else if (isConnected && !address) {
            pushGlobalError(new Error('No ETH address found'))
        } else if (isConnected && address) {
            showActiveWalletAccount()
        }
    }, [address, isConnected, provider])
    // useEffect(() => {
    //     if(isConnected){
    //         connectedCallback && connectedCallback()
    //     }
    // }, [isConnected])
    return <a {...props} 
        onClick={onConnect} 
        className={`
            relative 
            ${className}
            ${(isConnected && address) ? 'bg-lightGreen' : ''}
        `}
    >
        {/* CONNECT */}
        {/* {isConnected
            ? address
                ? <strong className="absolute bottom-[-1rem] right-[-0.25rem] text-green-500 text-2xl">&#8226;</strong>
                : <strong className="absolute bottom-[-1rem] right-[-0.25rem] text-orange-400 text-2xl">&#8226;</strong>
            : <strong className="absolute bottom-[-1rem] right-[-0.25rem] text-red text-2xl">&#8226;</strong>} */}
        {(isConnected && address)
            ? `${address.slice(0, 6)}..${address.slice(-4)} | ${balanceOf ? balanceOf : '0'} tokens`
            : ctaText
        }
    </a>
}

export default WalletConnect