export function partitionArray(arr, partitionLength){
    return arr && Array.isArray(arr) && (
        [...arr]
        .reduce(
            (accumulatedArray, q) => {
                let lastelem = accumulatedArray.pop()
                if (lastelem.length === partitionLength) {
                    return [...accumulatedArray, lastelem, [q]]
                } else {
                    lastelem.push(q)
                    return [...accumulatedArray, lastelem]
                }
            },
            [[]]
        )
    )
}