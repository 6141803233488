import { pushGlobalError } from '../../modules/errors/useErrors';
import { useCallback, useState } from 'react';
import { usePrepareContractWrite, useContractRead, useContractWrite, useAccount } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react';
import { BigNumber, utils } from 'ethers';
import { fetchMe, postLookingInMintedTokens } from '../../modules/user-profile/api';
import { identity$$ } from '../../modules/user-profile/observables';
import { NavLink } from 'react-router-dom';
import { TwitterShareButton } from 'react-twitter-embed';
import { useObservableState } from 'simple-observable-state';
import LookingInLeaderboard from '../../modules/looking-in/LookingInLeaderboard';
import MovingBackground from './MovingBackground';
import { mintDialog$$ } from './MintButton';
import Countdown from 'react-countdown';
import facelessvideo from '../../assets/mp4/faceless.mp4'

export default function MintPage({
    test,
    contractAddress,
    contractAbi,
    onNavigate,
    showBackground = true,
    className,
    ...props
}) {
    const [{ isLoading }, $$] = useObservableState(mintDialog$$)
    const { open: openWeb3Modal } = useWeb3Modal()
    const { address, isConnected } = useAccount()

    // mintPrice
    const {
        data: mintPriceData,
    } = useContractRead({
        address: contractAddress,
        abi: contractAbi,
        functionName: 'mintPrice',
    })
    // totalsupply
    const {
        data: totalSupply,
    } = useContractRead({
        address: contractAddress,
        abi: contractAbi,
        functionName: 'totalSupply',
    })

    // balanceOf
    const {
        data: balanceOf,
        refetch
    } = useContractRead({
        address: contractAddress,
        abi: contractAbi,
        args: [address],
        functionName: 'balanceOf',
    })

    // mint 
    const [tokenCount, setTokenCount] = useState(10)
    const [justMintedCount, setJustMintedCount] = useState(0)
    const [prepareError, setPrepareError] = useState()
    const {
        config,
        isRefetching,
        isFetching,
    } = usePrepareContractWrite({
        address: contractAddress,
        abi: contractAbi,
        functionName: 'mint',
        args: [+tokenCount],
        overrides: {
            value: mintPriceData
                ? mintPriceData.mul(tokenCount)
                : BigNumber.from(0)
        },
        onSuccess: data => {
            setPrepareError(void 0)
            console.log('success preparing mint', data)
        },
        onError: error => {
            console.warn('error preparing mint minting', error)
            setPrepareError(error)
        }
    })

    // const isLoading, setIsLoading] = useState(false)
    const { writeAsync: smartContractMint } = useContractWrite(config)

    const handleClick = useCallback(async function () {
        if (isFetching || isRefetching) {
            return
        }
        if (isLoading) {
            return
        } else if (!isConnected || !address) {
            return openWeb3Modal()
        }
        if (prepareError) {
            pushGlobalError(prepareError)
            return
        }
        if (!mintPriceData) {
            pushGlobalError(new Error('Error: Cannot get mint price'))
            return
        }
        if (!smartContractMint) {
            pushGlobalError(new Error('Error: Cannot connect to smart contract'))
            return
        }
        // setIsLoading(true)
        $$.next(dialog => { dialog.isLoading = true })
        smartContractMint()
            .then(tx => tx.wait())
            .then(mintReceipt => {
                setJustMintedCount(mintReceipt?.logs?.length || 0)
                refetch()
            })
            .then(_ => pushGlobalError({
                message: (
                    <div
                        className={`
                            rounded-md backdrop-blur-sm
                            font-LSC text-green-900 bg-green-300
                            line-clamp-2 p-4
                            -rotate-3
                            overflow-hidden
                        `}
                    >
                        <span>SUCCESSFUL <span>MINT!!</span></span>
                    </div>
                )
            }))
            .then(() => fetchMe())
            .then(identity => {
                identity$$.next(identity)
            })
            .catch(error => {
                pushGlobalError(error)
            })
            // .finally(() => { setIsLoading(false) })
            .finally(() => { $$.next(dialog => { dialog.isLoading = false }) })
    }, [
        config,
        isLoading,
        isConnected,
        address,
        prepareError,
        mintPriceData,
        openWeb3Modal,
        smartContractMint,
        $$
    ])
    // const [isMoreOpen, setIsMoreOpen] = useState()
    return (
        <div
            className={`
                py-[17vmin] pb-[17vh] sm:pb-[17vmin]
                text-white text-center 
                mx-2 
                grid xs:grid-cols-1 lg:grid-cols-2 justify-center gap-4
            `}
        >
            {showBackground ? <MovingBackground /> : null}
            <div className='flex flex-col gap-4'>
                {/* CTA */}
                <div className='p-6 bg-alphablackDark rounded-md w-full max-w-2xl'>
                    <p className='my-4 mb-8 text-3xl'>LOOKING IN <br /> <span className='text-[tomato]'>x</span> SNUFFY</p>
                    <p className='my-4 mb-8 text-2xl'>TIMED EDITION</p>
                    <div className='flex gap-4 justify-center items-center my-8'>
                        <button
                            className={`
                                        py-2 px-6 bg-lightGreen rounded-full
                                        ${(isFetching || isRefetching)
                                    ? 'opacity-40'
                                    : ''
                                }
                                        hover:scale-105
                                    `}
                            onClick={handleClick} {...props}
                        >
                            <span
                                style={{ transform: 'scale(1.5)' }}
                                className={isLoading ? 'text-white' : ''}
                            >
                                MINT{isLoading && (<span className='text-[tomato]'>ING.. </span>)}<span className='text-lg text-[tomato]'> {tokenCount}</span> TOKENS
                            </span>
                        </button>
                        {justMintedCount > 0
                            ? <div className='flex gap-4 justify-center items-center'>
                                <TwitterShareButton
                                    onLoad={function noRefCheck() { }}
                                    options={{
                                        buttonHashtag: undefined,
                                        screenName: undefined,
                                        size: 'large',
                                        text: `I've just minted ${justMintedCount || 'some'} token${justMintedCount > 1 ? 's' : ''}! Have you Looked In?`,
                                        via: 'snuffyNYC'
                                    }}
                                    placeholder="Loading"
                                    url="www.lookingin.nyc"
                                />
                            </div>
                            : null}
                    </div>
                    {mintPriceData
                        ? <div className='my-4 text-xl'>
                            {utils.formatEther(mintPriceData.mul(tokenCount))} ETH
                        </div>
                        : null}
                    <input
                        id="mint-token-count"
                        name="mint-token-count"
                        className={`w-full max-w-xs text-gray-600 flex-none rounded-md bg-alphawhite`}
                        type="range" min={1} max={20} step={1}
                        onChange={e => {
                            e.stopPropagation()
                            setTokenCount(+e.target.value)
                        }}
                        defaultValue={tokenCount || 0}
                    />
                    <p className='block text-center text-xs mb-4'>Use slider to choose how many tokens to mint</p>
                    <label className='block text-center mb-4'>20 tokens per transaction, unlimited tokens overall<br />top 5 token holders from mint are airdropped 1 of 1</label>
                </div>


                <div className='p-6 bg-alphablackDark rounded-md grid grid-cols-3 w-full max-w-2xl'>
                    <div className='flex flex-col justify-between'>
                        <div>PRICE:</div>
                        <div>{mintPriceData ? utils.formatEther(mintPriceData) : '.08'} ETH</div>
                        {/* <div>.08 ETH</div> */}
                    </div>
                    <div>
                        <div>TOTAL MINTED:</div>
                        <div>{Number(totalSupply) || "N/A"}</div>
                    </div>
                    <div>
                        <div>OPENS IN:</div>
                        <div>
                            <Countdown
                                date={new Date(Date.UTC(2023, 1, 23, 20))}
                                daysInHours={true}
                                zeroPadTime={2}
                            >
                                <span className='text-[tomato]'>OPEN!!</span>
                            </Countdown>
                        </div>
                    </div>
                </div>

                <div className={`
                    grid justify-items-stretch
                    xs:grid-cols-1 sm:grid-cols-2 gap-4 
                    w-full max-w-2xl
                `}>
                    <div className='flex flex-col gap-4'>
                        <h2 className='text-xl p-6 bg-alphablackDark rounded-md'>WHAT IS IT</h2>
                        <p className='p-6 bg-alphablackDark rounded-md text-left flex-grow'>
                            The token you mint is called a faceless card <br /><br />
                            Take the test and the art created is a direct reflection of your personality <br /><br />
                            Link the test to your token <br /><br />
                            1 token = 1 test
                        </p>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h2 className='text-xl p-6 bg-alphablackDark rounded-md'>LEADERBOARD</h2>
                        <div className='p-6 bg-alphablackDark rounded-md relative text-xs flex-grow'>
                            <LookingInLeaderboard
                                withBg={false}
                                hideTagline={true}
                                hideTitle={true}
                                withPaddingTop={false}
                                cnt={5}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-center'>
                <video
                    autoPlay={true}
                    playsInline={true}
                    loop={true}
                    controls={false}
                    muted={true}
                    className={`max-h-[calc(100vh_-_17vmin)] w-auto max-w-full rounded-lg`}
                >
                    <source src={facelessvideo} type="video/mp4"/>
                </video>
            </div>
        </div>
    )
}
