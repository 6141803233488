import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useObservableState } from "simple-observable-state";
import { partitionArray } from "../../../common/libs/partition-array";
import { nextError } from "../../errors/observables";
import { pushGlobalError } from "../../errors/useErrors";
import { getSessionFromStorage } from "../../user-profile/api";
import { storedTest$$ } from "./observables/stored-test-observable";

const testApi = () => axios.create({
    baseURL: process.env.REACT_APP_API_HOST + "/test/bigfive",
    headers: { 'mrwn-session': getSessionFromStorage() ? getSessionFromStorage() : '' }
})
const lookingInApi = () => axios.create({
    baseURL: process.env.REACT_APP_API_HOST + "/looking-in",
    headers: { 'mrwn-session': getSessionFromStorage() ? getSessionFromStorage() : '' }
})
const nftApi = () => axios.create({
    baseURL: process.env.REACT_APP_API_HOST + "/looking-in/nft",
    headers: { 'mrwn-session': getSessionFromStorage() ? getSessionFromStorage() : '' }
})

export function useTestBigFive(identity, linkHash) {
    const [test] = useObservableState(storedTest$$)
    useEffect(() => {
        if (linkHash) {
            testApi().get(`/invite/${linkHash}`)
                .then(res => res.data)
                .then(test => storedTest$$.next(test))
                .catch(err => {
                    if (err.response.status === 403) {
                        nextError(new Error("YOU MUST CONNECT TO CONTINUE"))
                    } else {
                        console.log('unexpected error:', err)
                    }
                })
        } else if (identity._id) {
            // console.log('fetch')
            testApi().get('')
                .then(res => res.data)
                .then(test => storedTest$$.next(test))
                .catch(err => {
                    if (err.response.status === 403) {
                        nextError(new Error("YOU MUST CONNECT TO CONTINUE"))
                    } else {
                        console.log('unexpected error:', err)
                    }
                })
        }
    }, [identity, linkHash])

    return {
        test,
        mintTokenIdForTest: spendTokenIdForTest,
        skipIntro,
        postStart,
        postAnswer,
        getResults,
        postSendInvite,
        postFreeFormInviteAnswer,
        getRandomResults,
        postFreeFormAnswer,
        postResetTest,
        postIsRevealed,
    }
}

export function useTestBigFiveApi() {
    return {
        mintTokenIdForTest: spendTokenIdForTest,
        skipIntro,
        postStart,
        postAnswer,
        getResults,
        postSendInvite,
        postFreeFormInviteAnswer,
        getRandomResults,
        postFreeFormAnswer,
        postResetTest,
        postIsRevealed,
    }
}

export function useLookingInLeaderboard(cnt=10){
    const [leaderboard, setLeaderboard] = useState()
    useEffect( () => {
        lookingInApi().get('/leaderboard')
            .then(resp => resp.data)
            .then( data => {
                if(data && data.length){
                    const orderedData = data.sort( (b,a) => (a.balanceOf - b.balanceOf)).slice( 0, cnt )
                    setLeaderboard(orderedData)
                }
            })
            .catch(pushGlobalError)
    }, [setLeaderboard])

    return leaderboard
}

export function useTestPages(test) {
    const pages = useMemo(
        () => test?.inventory && (partitionArray(test.inventory.sort((a, b) => a.num - b.num), test.itemsPerPage)),
        [test]
    )
    return pages
}
export function useTestChapters(pages, pagesPerChapter) {
    const chapters = useMemo(
        () => partitionArray(pages, pagesPerChapter),
        [pages, pagesPerChapter]
    )
    return chapters
}

export function useTestParsed(test) {
    const pagesPerChapter = test?.inventory?.length
        ? Math.ceil(test?.inventory?.length / test.itemsPerPage)
        : 0
    const pages = useTestPages(test)
    const chapters = useTestChapters(pages, pagesPerChapter)
    const chapterIdx = useMemo(() => Math.floor(test.answers.length / test.itemsPerPage / pagesPerChapter), [test, pagesPerChapter])
    const chapter = chapters && chapters?.[chapterIdx]
    const formsAnswers = useMemo(() => Object.values(test.formsAnswers), [test.formsAnswers])
    const hasQuestions = test.answers.length < test.inventory.length
    const hasFreeForms = useMemo(() => formsAnswers.length < test.formsInventory.length, [formsAnswers, test.formsInventory.length])

    return {
        pages,
        chapters,
        chapter,
        chapterIdx,
        formsAnswers,
        hasFreeForms,
        hasQuestions
    }
}

export function useIdentityTests(toggle) {
    const [tests, setTests] = useState([])
    useEffect(() => {
        testApi().get('/all')
            .then(response => response?.data?.sort((a, b) => new Date(b.created) - new Date(a.created)))
            .then(tests => {
                setTests(tests)
            })
            .catch(error => {
                pushGlobalError(new Error('MRWN: unable to fetch all tests'))
                console.log(error)
            })
    }, [toggle])

    return tests
}

export function useTestById(id) {
    if (!id) {
        throw new Error('Unable to fetch test by ID')
    }
    const [test, setTest] = useState(void 0)
    useEffect(() => {
        testApi().get(`/id/${id}`)
            .then(response => response?.data)
            .then(test => {
                setTest(test)
            })
            .catch(error => {
                pushGlobalError(new Error('MRWN: unable to fetch test'))
                console.log(error)
            })
    }, [])

    return test
}

export function useAllLinkedTokenIds(resetSwitch) {
    const [tokenIds, setTokenIds] = useState([])
    useEffect(() => {
        lookingInApi().get('/all/tokenId')
            .then(response => response.data)
            .then(IDs => { setTokenIds(IDs.map( id => +id )) })
            .catch(error => { console.error(error) })
    }, [setTokenIds, resetSwitch])

    return tokenIds
}

async function spendTokenIdForTest(tokenId, testId) {
    if (!tokenId || !testId) {
        console.warn('invalid parameters for spendTokenIdForTest')
    }
    return nftApi().post(`/${tokenId}`, { testId })
        .then(res => res.data)
        .then(test => {
            storedTest$$.next(test)
            return test
        })

}


function skipIntro(e) {
    e.preventDefault()
    function _skip(test) {
        // console.log(test)
        test?.flags && (test.flags.intro = true)
    }
    storedTest$$.next(_skip)
}

function postStart() {
    testApi().post('/start', {})
        .then(res => res.data)
        .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                console.log('unexpected error:', err)
            }
        })
}

function postAnswer({ questionID, score, linkHash }) {
    if (linkHash) {
        return testApi().post('/invite', {
            linkHash,
            questionID,
            score
        })
            .then(res => res.data)
            .then(test => storedTest$$.next(test))
            .catch(err => {
                if (err.response.status === 403) {
                    nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
                } else {
                    console.log('unexpected error:', err)
                }
            })
    } else {
        return testApi().post('', {
            questionID,
            score
        })
            .then(res => res.data)
            .then(test => storedTest$$.next(test))
            .catch(err => {
                if (err.response.status === 403) {
                    nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
                } else {
                    console.log('unexpected error:', err)
                }
            })
    }
}
function postIsRevealed(testId) {
    return testApi().post('/revealed', { testId })
        .then(res => res.data)
        .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                console.log('unexpected error:', err)
            }
        })
}
function postResetTest() {
    return testApi().post('/reset')
        .then(res => res.data)
        .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                console.log('unexpected error:', err)
            }
        })
}

function postFreeFormAnswer({ formId, text }) {
    return testApi().post('/freeform', {
        formId,
        text
    })
        .then(res => res.data)
        .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                pushGlobalError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                // console.log('unexpected error:', err)
                throw err
            }
        })
}

function postFreeFormInviteAnswer({ formId, text, linkHash }) {
    return testApi().post('/invite/freeform', {
        formId,
        text,
        linkHash
    })
        .then(res => res.data)
        .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                console.log('unexpected error:', err)
            }
        })
}

function postSendInvite({ email, sender }) {
    return testApi().post('/invite/send', {
        email,
        sender
    })
        .then(res => res.data)
        // .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                console.log('unexpected error:', err)
            }
        })
}

function getResults() {
    return testApi().get('/results')
        .then(res => res.data)
        .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                console.log('unexpected error:', err)
            }
        })
}

function getRandomResults() {
    return testApi().get('/randomized/results')
        .then(res => res.data)
        .then(test => storedTest$$.next(test))
        .catch(err => {
            if (err.response.status === 403) {
                nextError(new Error("YOU MUST BE CONNECTED TO ANSWER"))
            } else {
                console.log('unexpected error:', err)
            }
        })
}

window.randomResults = getRandomResults