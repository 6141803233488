import axios from 'axios'
import { getTokenFromStorage } from '../nifty/utils'
import { catchError, debounceTime, distinctUntilKeyChanged, firstValueFrom, from, map, share, Subject, switchMap, tap, throttleTime, throwError } from 'rxjs'
import { personalSign } from '../ethereum/libs/metamask-personal-sign'
import { nextError } from '../errors/observables'
import { pushGlobalError } from '../errors/useErrors'

export const VENDORS = {
    eth_addr: 'eth_addr',
    ng_username: 'ng_username',
    email: 'email',
    beta_key: 'beta_key',
    beta_email: 'beta_email',
    booking_form: 'booking_form'
}

export const identityApi = () => axios.create({
    baseURL: process.env.REACT_APP_API_HOST+"/identities",
    headers: { 
        'mrwn-session': getSessionFromStorage() ? getSessionFromStorage() : '',
        'client-id': getClientFromStorage() ? getClientFromStorage() : ''
    }
})
export const contactApi = axios.create({
    baseURL: process.env.REACT_APP_API_HOST+"/contact"
})

export async function postEmailConfirmation(code){
    return identityApi().post('/email/confirm', {
        id: code
    }).then(res => res.data)
}

export async function postRegisterEmail({
    email,
    password,
    confirmPassword
}){
    return identityApi().post('/email/register', {
        email,
        password,
        confirmPassword
    }).then(res => res.data)
}

export async function postRequestEmailReset({
    email
}){
    return identityApi().post('/email/request/reset-password', {
        email
    }).then(res => res.data)
}

export async function postResetEmailPassword({
    email,
    id,
    newPassword,
    confirmPassword
}){
    return identityApi().post(`/email/reset-password/${id}`, {
        email,
        newPassword,
        confirmPassword
    }).then(res => res.data)
}

export async function postAddKey({ vendor, key }){
    try {
        let proof
        switch (vendor) {
            case VENDORS.ng_username:
                proof = getTokenFromStorage()
                break;
            case VENDORS.eth_addr:
                proof = await personalSign(key)
                break;
        
            default:
                throw new Error('MRWN: unknown vendor type');
        }
        return identityApi().post('/merge', { proof, vendor, key })
            .then(res => res.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function postContactEmail({ vendor, key, email, ...bookingFields }) {
    try {
        contactApi.post('', { vendor, key, email, ...bookingFields })
            .then(console.log)
            .catch(console.log)
    } catch (error) {
        return Promise.reject(error)
    }
}

export async function postLookingInContactEmail({ email, domain, nickname }) {
    try {
        return contactApi.post('/lookingin', { email, domain, nickname })
    } catch (error) {
        return Promise.reject(error)
    }
}
export async function postLookingInMintedTokens({ tokenIds }) {
    try {
        return identityApi().post('/minted', { tokenIds })
            .then(res => res.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * @Dev This sends the error to be notified so that callers of login do not each show the same error multiple times
 */
const loginCalls$$ = new Subject().pipe(
    throttleTime(130),
    distinctUntilKeyChanged( 'key', (a,b) => a!==b ),
    switchMap(({ vendor, key, proof }) => {
        return from( identityApi().post('/login', { vendor, key, proof }).catch(pushGlobalError) )
            .pipe(
                map(res => res.data), // res.data = sessionID
                tap(authenticate),
                // catchError(pushGlobalError),
                catchError( err => {
                    // trigger error nitification
                    // pushGlobalError(err)
                    return throwError(() => err)
                })
            )
    }),
    share(),
    // emits: [identityKey, identity]
)
// console.log(loginCalls$$)


const meCalls$$ = new Subject().pipe(
    debounceTime(150),
    switchMap( () => from(identityApi().get('me')
        .then( res => res.data )) 
    )).pipe( catchError( err => {
        // trigger error nitification
        nextError(err)
        throw err
    }),
    share()
)
export function fetchMe(){
    let identityPromise = firstValueFrom( meCalls$$ )
    meCalls$$.next(0)

    return identityPromise
}

export function authenticateIdentity({ vendor, key, proof }){
    // promise of the identity matching vendor/key pair. Subject is used to reduce redundant http requests
    let identityPromise = firstValueFrom( loginCalls$$ )
    loginCalls$$.next({ vendor, key, proof })

    return identityPromise
}

export function authenticate([sessionID, clientID]) {
    try {
        sessionID && setSessionInStorage(sessionID)    
        clientID && setClientInStorage(clientID)    
    } catch (error) {
        console.warn('authenticate:error:', error)
    }
}
// clean up localStorage
localStorage.removeItem('mrwn_session')
export function unauthenticateProfile() {
    sessionStorage.removeItem('mrwn_session')
}

export function getSessionFromStorage(){
    return sessionStorage.mrwn_session
}
export function getClientFromStorage(){
    return localStorage.client_id
}
export function setSessionInStorage(id){
    return sessionStorage.setItem('mrwn_session', id) || void 0
}
export function setClientInStorage(id){
    return localStorage.setItem('client_id', id) || void 0
}

// try {
//     if(!vendor || !key) return Promise.reject( new TypeError('not connected'))
//     if(!email) return Promise.reject( new TypeError('invalid email'))
//     let vipKey = await profileApi.post('/vips', { vendor, key, email })
//     return vipKey
// } catch (error) {
//     if(error.response.status === 403) {
//         // error.message = <><span className='text-[tomato] my-4 text-lg'></span><NavLink to="/nfts">You can join the VIP list by owning Snuffy's NFTs <small>(click to NFT Catalog)</small></NavLink></>
//         return Promise.resolve({})
//     }
//     return Promise.reject(error)
// }

// export async function postContactEmail({ vendor, key, email }) {
//     try {
//         profileApi.post('/vips/ping', { email }).then(console.log).catch(console.log)
//     } catch (error) {
//         console.log(error)
//     }
//     try {
//         if(!vendor || !key) return Promise.reject( new TypeError('not connected'))
//         if(!email) return Promise.reject( new TypeError('invalid email'))
//         let vipKey = await profileApi.post('/vips', { vendor, key, email })
//         return vipKey
//     } catch (error) {
//         if(error.response.status === 403) {
//             // error.message = <><span className='text-[tomato] my-4 text-lg'></span><NavLink to="/nfts">You can join the VIP list by owning Snuffy's NFTs <small>(click to NFT Catalog)</small></NavLink></>
//             return Promise.resolve({})
//         }
//         return Promise.reject(error)
//     }
// }