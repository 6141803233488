// import Web3 from 'web3'

import { getSessionFromStorage } from "../../user-profile/api";

const ETH_SIGN_MSG = uniqString => `To continue, we need you to confirm you control this wallet. This is FREE. In fact, you are only signing this unique code: ${uniqString}.`

export const personalSign = async (from, message=ETH_SIGN_MSG( getSessionFromStorage() )) => {
    // const web3 = new Web3(Web3.givenProvider)
    const exampleMessage = message || 'invalid message';
    try {
        // const from = accounts[0];
        const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
        const signedMessage = await window.ethereum.request({
            method: 'personal_sign',
            params: [msg, from],
        });

        return signedMessage
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
};