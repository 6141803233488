import { config } from "@react-spring/web"

export const slideRight = {
    from: {
        x: -200
    },
    to: {
        x: 0
    }
}
export const zoomOut = {
    from: {
        transform: 'scale(1.5)'
    },
    to: {
        transform: 'scale(1)'
    }
}
export const fadeIn = {
    from: {
        opacity: 0
    },
    to: {
        opacity: 1
    }
}
export const fadeInTop = {
    from: {
        opacity: 0,
        y: -200
    },
    to: {
        opacity: 1,
        y: 0
    }
}
export const fadeInLeft = {
    from: {
        opacity: 0,
        x: -200
    },
    to: {
        opacity: 1,
        x: 0
    }
}

export const pulseLoop = (fromColor = 'black', toColor = 'tomato') => ({
    from: { scale: 1, color: fromColor },
    to: [
        { scale: 1.1, color: toColor },
        { scale: 1, color: fromColor },
    ],
    loop: true,
    config: {
        ...config.wobbly,
        tension: 300,
        mass: 2
    }
})