import { useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Subject } from 'rxjs';

export class Success extends Error {
    constructor(...args){
        super(...args)
    }
}

const globalErrorsSubject = new Subject()
const globalSuccessesSubject = new Subject()

const getPushToSubjectWithTimestamp = subject => error => {
    try {
        if(!error || !subject.next) { return }
        error.timestamp = Date.now()
        subject.next(error)
    } catch (error) {
        console.warn('pushError')
    }
}

export const pushGlobalError = getPushToSubjectWithTimestamp(globalErrorsSubject)
export const pushGlobalSuccess = getPushToSubjectWithTimestamp(globalSuccessesSubject)

export function useErrors(timeoutMs, noGlobal = false) {
    const errorsSubject = useMemo(() => noGlobal ? new Subject() : globalErrorsSubject, [noGlobal])
    const [errors, setErrors] = useState([])
    const pushError = useCallback(getPushToSubjectWithTimestamp(errorsSubject), [errorsSubject])
    const clearError = useCallback((error) => {
        if (!errors || !errors.includes(error)) {
            console.warn('attempted to clear invalid success', error)
        } else {
            setErrors(errors.filter(err => err !== error))
        }
    }, [errors, setErrors])
    useEffect(() => {
        let timeoutsTeardownFunctions = []
        let hotSub = errorsSubject.subscribe({
            next: error => {
                const clearAfterTimeout = setTimeout(() => {
                    // TODO: debug. error is undefined
                    clearError(error)
                }, timeoutMs)
                const teardownFunction = () => {
                    clearAfterTimeout && clearTimeout(clearAfterTimeout)
                }
                timeoutsTeardownFunctions.push(teardownFunction)
                setErrors(prev => {
                    if(!prev) return [error]
                    if (!prev.includes(error)) {
                        return [...prev, error]
                    }
                })
            }
        })

        return () => {
            hotSub.unsubscribe()
            timeoutsTeardownFunctions.forEach(teardown => {
                try {
                    teardown()
                } catch (error) {
                    console.warn('error in teardown', error)
                }
            })
        }
    }, [setErrors, timeoutMs])

    return {
        pushError,
        clearError,
        errors
    }
}
export function useSuccess(timeoutMs, noGlobal = false) {
    const errorsSubject = useMemo(() => noGlobal ? new Subject() : globalSuccessesSubject, [noGlobal])
    const [errors, setErrors] = useState([])
    const pushError = useCallback(getPushToSubjectWithTimestamp(errorsSubject), [errorsSubject])
    const clearError = useCallback((error) => {
        if (!errors || !errors.includes(error)) {
            console.warn('attempted to clear invalid error', error)
        } else {
            setErrors(errors.filter(err => err !== error))
        }
    }, [errors, setErrors])
    useEffect(() => {
        let timeoutsTeardownFunctions = []
        let hotSub = errorsSubject.subscribe({
            next: error => {
                const clearAfterTimeout = setTimeout(() => {
                    clearError(error)
                }, timeoutMs)
                const teardownFunction = () => {
                    clearAfterTimeout && clearTimeout(clearAfterTimeout)
                }
                timeoutsTeardownFunctions.push(teardownFunction)
                setErrors(prev => {
                    if (!prev.includes(error)) {
                        return [...prev, error]
                    }
                })
            }
        })

        return () => {
            hotSub.unsubscribe()
            timeoutsTeardownFunctions.forEach(teardown => {
                try {
                    teardown()
                } catch (error) {
                    console.warn('error in teardown', error)
                }
            })
        }
    }, [setErrors, timeoutMs])

    return {
        pushError,
        clearError,
        errors
    }
}