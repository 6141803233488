// import { useObservableState } from "simple-observable-state"
// import { identity$$ } from "../user-profile/observables"
import { useLookingInLeaderboard } from "./test-bigfive/hooks"
import { useAccount } from "wagmi"
import MovingBackground from "../../common/components/MovingBackground"
// import { useEffect } from "react"
// import { useState } from "react"

export default function LookingInLeaderboard({
    withPaddingTop=true,
    hideTagline = false,
    hideTitle=false,
    withBg = true,
    cnt=10,
    ...props
}) {
    // const [identity] = useObservableState(identity$$)
    const { address: currentAddress } = useAccount()
    const leaderboard = useLookingInLeaderboard(cnt)
    // console.log(leaderboard)
    return <div className={withPaddingTop ? `py-[17vmin] pb-[17vh] sm:pb-[17vmin]` : ``}>
        {withBg ? <MovingBackground noPaint={true} /> : null}
        {hideTitle ? null : <div className="flex justify-center">
            <h1 className={`inline-block mx-2 my-12 text-2xl bg-alphawhite text-gray-700 p-4 px-8 rounded-md -rotate-3`}>
                LEADERBOARD
            </h1>
        </div>}
        <div
            className={`mx-2 grid gap-4 grid-cols-1 justify-items-center auto-cols-max`}
        >
            {hideTagline
                ? null
                : <p
                    className={`
                    w-full max-w-lg p-4 bg-rose-200 text-rose-800 bg-opacity-80 -skew-x-3 rotate-2 my-2 text-lg
                `}
                >
                    TOP
                    <span className="inline-block px-2 text-2xl"> 5 </span>
                    WILL BE AIRDROPPED THE
                    <span className="inline-block px-2 text-2xl"> 1 OF 1 </span>
                    OF THEIR PRIMARY FACET
                </p>}
            {(leaderboard && leaderboard?.length)
                ? leaderboard.map(({ address, balanceOf, tokens }, idx) => {
                    const isCurrentAddress = !!currentAddress && address?.toUpperCase() === currentAddress?.toUpperCase()
                    const isWalletAddressRgxp = /^0x[a-zA-z0-9]{40}/gi
                    const leaderboardName = isWalletAddressRgxp.test(address)
                        ? `${address.slice(0, 6)}..${address.slice(-4)}`
                        : address
                    return <div
                        key={address}
                        className={`
                            w-full max-w-lg p-4
                            flex gap-8 justify-beetween
                            text-center
                            rounded-md
                            bg-alphablack text-white
                            ${isCurrentAddress
                                ? 'bg-alphawhite text-gray-700'
                                : 'bg-alphablack text-white'
                            }
                        `}
                    >
                        <span>
                            {`#${idx + 1}`}
                        </span>
                        <span className="flex-grow">
                            {leaderboardName}
                        </span>
                        <span>
                            {balanceOf}
                        </span>
                    </div>
                })
                : null
            }
        </div>
    </div>
}